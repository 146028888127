<template>
  <v-container fluid>
    <v-row id="content">
      <v-col>
        <h1>Dashboard Admin</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Stats :stats="stats" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Users :users="users" :key="usersKey"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <HomePageImage />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import Stats from "@/components/admin/Dashboard/Stats";
import Users from "@/components/admin/Dashboard/Users";
import HomePageImage from "@/components/admin/Dashboard/HomePageImage";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "Dashboard",

  components: {
    Stats,
    Users,
    HomePageImage,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["stats", "users", "usersKey"]),
  },

  methods: {
    getDashboardUsers() {
      this.loading = true;
      this.$store.dispatch("getDashboardUsers").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    getStats() {
      this.loading = true;
      this.$store.dispatch("getStats").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
  },

  mounted() {
    this.getStats();
    this.getDashboardUsers();
  },
};
</script>
