<template>
  <v-card>
    <v-card-title class="card-title">
      <h3>
        {{ $t("dataTableUserLinks.title") }}
      </h3>
    </v-card-title>

    <v-card-text>
      <v-container
        class="pa-0"
        :class="{ 'pa-3': $vuetify.breakpoint.mdAndUp }"
      >
        <v-row>
          <v-col>
            <v-data-table
              id="content"
              :headers="headers"
              :items="items"
              :search="search"
              sort-by="text"
              :items-per-page="5"
              @click:row="userClicked"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('admin.machines.search')"
                    single-line
                    hide-details
                    style="max-width: 300px;"
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.selected="{ item }">
                <v-icon v-show="item.selected">
                  mdi-check-bold
                </v-icon>
              </template>
              <template v-slot:item.linkName="{ item }">
                <v-text-field
                  :id="`row-${item.userID}`"
                  v-model="item.linkName"
                  @input="linkNameChanged(item)"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions
      :class="{
        'padding-action-small': $vuetify.breakpoint.smAndDown,
        'padding-action': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-container class="px-1 py-0">
        <v-row>
          <v-col class="pt-0">
            <v-btn
              depressed
              tile
              v-html="$t('dataTableUserLinks.cancelButton')"
              class="primary white--text full-width"
              :disabled="dataTableSettingsDialog.loading"
              :loading="dataTableSettingsDialog.loading"
              @click="cancel"
            ></v-btn>
          </v-col>
          <v-col class="pt-0">
            <v-btn
              depressed
              tile
              v-html="$t('dataTableUserLinks.updateButton')"
              class="primary white--text full-width"
              :disabled="dataTableSettingsDialog.loading"
              :loading="dataTableSettingsDialog.loading"
              @click="save"
            ></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DataTableUserLinks",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    parentID: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("dataTableUserLinks.selectedLabel"),
          value: "selected",
        },
        {
          text: this.$t("dataTableUserLinks.emailLabel"),
          value: "email",
        },
        {
          text: this.$t("dataTableUserLinks.linkLabel"),
          value: "linkName",
        },
      ],
      originalItems: [],
      search: "",
    };
  },

  computed: {
    ...mapGetters(["dataTableSettingsDialog"]),
  },

  methods: {
    copyItems() {
      this.originalItems = [];

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const original = JSON.parse(JSON.stringify(item));
        this.originalItems.push(original);
      }
    },
    userClicked(item) {
      document.getElementById(`row-${item.userID}`).focus();
    },
    linkNameChanged(item) {
      if (item.linkName && item.linkName.length > 0) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    },
    cancel() {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        const original = this.originalItems.find(
          (itm) => itm.email == item.email
        );

        if (original) {
          item.linkName = original.linkName;
          item.selected = original.selected;
        }
      }

      this.$emit("cancel");
    },
    save() {
      this.$emit("updateUserLinks", {
        parentID: this.parentID,
        list: this.items,
      });
    },
  },

  mounted() {
    this.copyItems();
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}

.v-btn {
  max-height: 30px;
  min-height: 23px;
}
</style>
