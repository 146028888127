<template>
  <v-data-table id="content"
    :headers="headers"
    :items="stats"
    sort-by="email"
    :loading="loading"
    :loading-text="$t('admin.dashboard.stats.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title><h2>{{ $t('admin.dashboard.stats.title') }}</h2></v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:item.emailVerified="{ item }">
      <v-simple-checkbox v-model="item.emailVerified" disabled></v-simple-checkbox>
    </template>
    <template v-slot:no-data>
      <span>{{ $t('admin.dashboard.stats.noStatsFound') }}</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "Stats",

  props: {
    stats: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data() {
    return {
      headers: [
        { text: this.$t("admin.dashboard.stats.title"), value: "title" },
        {
          text: this.$t("admin.dashboard.stats.statValueLabel"),
          value: "statValue",
          align: "center"
        },
        {
          text: this.$t("admin.dashboard.stats.updatedDateLabel"),
          value: "updatedDate",
          align: "center"
        }
      ],
      loading: false
    };
  }
};
</script>