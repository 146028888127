<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="users"
    :search="search"
    sort-by="email"
    :loading="loading"
    :loading-text="$t('admin.dashboard.users.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.dashboard.users.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.dashboard.users.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-dialog
          v-model="updateUserDialog"
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="500px"
        >
          <v-card flat>
            <v-card-title>
              <span class="headline">{{
                $t("admin.dashboard.users.updateUser")
              }}</span>
            </v-card-title>
            <v-card-text>
              <v-container class="pa-0">
                <v-row>
                  <v-col>
                    <span
                      v-html="$t('admin.dashboard.users.email')"
                      class="card-text-caption"
                    />:
                    <span
                      v-html="editedItem.email"
                      class="card-text-description"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span
                      v-html="$t('admin.dashboard.users.companyName')"
                      class="card-text-caption"
                    />:
                    <span
                      v-html="editedItem.companyName"
                      class="card-text-description"
                    />
                  </v-col>
                  <v-col class="pt-0">
                    <span
                      v-html="$t('admin.dashboard.users.phone')"
                      class="card-text-caption"
                    />:
                    <span
                      v-html="editedItem.phone"
                      class="card-text-description"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span
                      v-html="$t('admin.dashboard.users.address')"
                      class="card-text-caption"
                    />:
                    <span
                      v-html="editedItem.address"
                      class="card-text-description"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span
                      v-html="$t('admin.dashboard.users.fullName')"
                      class="card-text-caption"
                    />:
                    <span
                      v-html="editedItem.fullName"
                      class="card-text-description"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-checkbox
                      v-model="editedItem.marketingEmail"
                      :label="$t('admin.dashboard.users.marketingEmailLabel')"
                      :disabled="loading"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-checkbox
                      v-model="editedItem.adminGroup"
                      :label="$t('admin.dashboard.users.adminGroupLabel')"
                      :disabled="loading"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-container class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4" class="pt-0">
                    <v-btn
                      depressed
                      tile
                      v-html="$t('admin.dashboard.users.cancelButton')"
                      class="primary white--text full-width"
                      :disabled="loading"
                      :loading="loading"
                      @click="cancel"
                    ></v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0">
                    <v-btn
                      depressed
                      tile
                      v-html="$t('admin.dashboard.users.saveButton')"
                      class="primary white--text full-width"
                      :disabled="loading"
                      :loading="loading"
                      @click="save"
                    ></v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0">
                    <v-btn
                      depressed
                      tile
                      v-html="$t('admin.dashboard.users.deleteButton')"
                      class="primary white--text full-width"
                      :disabled="loading"
                      :loading="loading"
                      @click="deleteUser"
                    ></v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="userLinksDialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="650px"
        >
          <DataTableUserLinks
            :items="dataTableItems"
            :parentID="parentID"
            @cancel="closeUserLinksDialog"
            @updateUserLinks="userLinksUpdated($event)"
            :key="dataTableUserLinksKey"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.email="{item}">
      <a :href="`mailto:${item.email}`">{{ item.email}}</a>
    </template>
    <template v-slot:item.emailVerified="{ item }">
      <v-btn
        v-if="!item.emailVerified"
        depressed
        tile
        v-html="$t('admin.dashboard.users.sendEmailButton')"
        class="primary white--text"
        small
        :disabled="loading"
        :loading="loading"
        @click="sendEmailVerification(item)"
      ></v-btn>
      <v-simple-checkbox
        v-else
        v-model="item.emailVerified"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.admin="{ item }">
      <v-btn
        v-if="!item.admin"
        depressed
        tile
        v-html="$t('admin.dashboard.users.enableButton')"
        class="primary white--text button-action"
        small
        :disabled="loading || !item.emailVerified"
        :loading="loading"
        @click="updateUserAdmin(item, true)"
      ></v-btn>
      <v-btn
        v-else
        depressed
        tile
        v-html="$t('admin.dashboard.users.disableButton')"
        class="secondary white--text button-action"
        small
        :disabled="loading || !item.emailVerified"
        :loading="loading"
        @click="updateUserAdmin(item, false)"
      ></v-btn>
    </template>
    <template v-slot:item.active="{ item }">
      <v-btn
        v-if="!item.active"
        depressed
        tile
        v-html="$t('admin.dashboard.users.enableButton')"
        class="primary white--text button-action"
        small
        :disabled="loading || !item.emailVerified"
        :loading="loading"
        @click="updateUserActive(item, true)"
      ></v-btn>
      <v-btn
        v-else
        depressed
        tile
        v-html="$t('admin.dashboard.users.disableButton')"
        class="secondary white--text button-action"
        small
        :disabled="loading || !item.emailVerified"
        :loading="loading"
        @click="updateUserActive(item, false)"
      ></v-btn>
    </template>
    <template v-slot:item.marketingEmail="{ item }">
      <v-simple-checkbox
        v-model="item.marketingEmail"
        disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.adminGroup="{ item }">
      <v-simple-checkbox v-model="item.adminGroup" disabled></v-simple-checkbox>
    </template>
    <template v-slot:item.userLinks="{ item }">
      <v-btn
        depressed
        tile
        class="primary white--text button-action"
        small
        :disabled="loading || !item.emailVerified"
        :loading="loading"
        @click="openUserLinks(item)"
      >
        {{ linkCaption(item) }}
      </v-btn>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.dashboard.users.noUsersFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import DataTableUserLinks from "@/components/admin/Dashboard/DataTableUserLinks";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "Users",

  props: {
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  components: {
    DataTableUserLinks,
  },

  data() {
    
    return {
      headers: [
        { text: this.$t("admin.dashboard.users.email"), value: "email" },
        {
          text: this.$t("admin.dashboard.users.emailVerifiedLabel"),
          value: "emailVerified",
          align: "center",
        },
        {
          text: this.$t("admin.dashboard.users.active"),
          value: "active",
          align: "center",
        },
        {
          text: this.$t("admin.dashboard.users.adminLabel"),
          value: "admin",
          align: "center",
        },
        {
          text: this.$t("admin.dashboard.users.marketingEmailLabel"),
          value: "marketingEmail",
          align: "center",
        },
        {
          text: this.$t("admin.dashboard.users.adminGroupLabel"),
          value: "adminGroup",
          align: "center",
        },
        {
          text: this.$t("admin.dashboard.users.userLinkLabel"),
          value: "userLinks",
          align: "center",
        },
        {
          text: this.$t("admin.dashboard.users.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      search: "",
      updateUserDialog: false,
      userLinksDialog: false,
      editedIndex: -1,
      editedItem: {
        userID: 0,
        emailVerified: false,
        admin: false,
        marketingEmail: false,
        adminGroup: false,
        active: false,
        companyName: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        address: "",
        phone: "",
        firstName: "",
        lastName: "",
        fullName: "",
      },
      defaultItem: {
        userID: 0,
        emailVerified: false,
        admin: false,
        marketingEmail: false,
        adminGroup: false,
        active: false,
        companyName: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        address: "",
        phone: "",
        firstName: "",
        lastName: "",
        fullName: "",
      },
      loading: true,
      dataTableItems: [],
      originalDataTableItems: [],
      parentID: null,
    };
  },

  computed: {
    ...mapGetters(["dataTableUserLinksKey"]),
  },

  methods: {
    linkCaption(item) {
      
      let title = this.$t("admin.dashboard.users.userLinkLabel");

      if (item.userLinks && item.userLinks.length > 0) {
        title += " (" + item.userLinks.length + ")";
      }
      this.loading = false;
      return title;
    },
    editItem(item) {
      /***Getting record from service***/

      this.loading = true;

      this.$store
        .dispatch("getUserOnEdit", { userID: item.userID })
        .then(
          (response) => {
            this.loading = false;
  
            try {
               /***Updating record***/

              
                this.editedIndex = this.users.indexOf(item);

                item.companyName = response.user.CompanyName;
                item.country = response.user.Country;
                item.street = response.user.Street;
                item.city = response.user.City;
                item.state = response.user.State;
                item.postalCode = response.user.PostalCode;
                item.firstName = response.user.FirstName;
                item.lastName = response.user.LastName; 
                item.phone = response.user.Phone; 
                item.fullName = response.user.FirstName+' '+response.user.LastName;

                let fullAddress = '';
                
                if (response.user.Street !=null && response.user.Street !='') {
                    fullAddress +=response.user.Street;
                }

                if (response.user.City !=null && response.user.City !='') {
                    fullAddress +=', '+response.user.City;
                }

                if (response.user.State !=null && response.user.State !='') {
                    fullAddress +=', '+response.user.State;
                }
                
                if (response.user.PostalCode !=null && response.user.PostalCode !='') {
                    fullAddress += ', '+response.user.PostalCode.toString();
                }

                if (response.user.Country !=null && response.user.Country !='') {
                    fullAddress +=', '+response.user.Country;
                }

                item.address = fullAddress;

                this.editedItem = Object.assign({}, item);
                
                this.updateUserDialog = true;
               
            } catch (error) {
                this.editedIndex = this.users.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.updateUserDialog = true;
               
            }

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
            //this.close();
          },
          (error) => {
            this.loading = false;
            
            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.updateUserDialog = true;

            this.$store.dispatch("showSnackbar", notification);
          }
        );
  

      /***End of getting record from service**/

      
    },
    cancel() {
      this.close();
    },
    save() {
      this.loading = true;
      
      this.$store.dispatch("updateDbUser", this.editedItem).then(
        (response) => {
          this.loading = false;
          
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.close();
        },
        (error) => {
          this.loading = false;
          
          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    deleteUser() {
      if (!confirm(this.$t("admin.dashboard.users.deleteAccountMessage"))) {
        return;
      }

      this.loading = true;
      
      this.$store.dispatch("deleteDbUser", this.editedItem).then(
        (response) => {
          this.loading = false;
          
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.close();
        },
        (error) => {
          this.loading = false;
          
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    updateUserAdmin(item, admin) {
      const action = admin
        ? this.$t("admin.dashboard.users.enableAdmin")
        : this.$t("admin.dashboard.users.disableAdmin");
      if (!confirm(action)) {
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("updateUserAdmin", { item: item, admin: admin })
        .then(
          (response) => {
            this.loading = false;
            
            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
            this.close();
          },
          (error) => {
            this.loading = false;
            
            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    updateUserActive(item, active) {
      
      const action = active
        ? this.$t("admin.dashboard.users.enableActive")
        : this.$t("admin.dashboard.users.disableActive");
      if (!confirm(action)) {
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("updateUserActive", { item: item, active: active })
        .then(
          (response) => {
            this.loading = false;
           
            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
            this.close();
          },
          (error) => {
            this.loading = false;
          
            const notification = {
              show: true,
              result: false,
              message: error.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    sendEmailVerification(item) {
      if (!confirm(this.$t("admin.dashboard.users.sendEmailVerification"))) {
        return;
      }

      this.loading = true;

      this.$store.dispatch("sendEmailVerification", { item: item }).then(
        (response) => {
          this.loading = false;
          
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.close();
        },
        (error) => {
          this.loading = false;
         
          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    openUserLinks(item) {
      this.updateDataTableItems(item);
      this.userLinksDialog = true;
    },
    updateDataTableItems(item) {
      const items = [];
      
      this.users.forEach((user) => {
        let userLinkID = 0;
        let selected = false;
        let linkName = "";

        if (item.userLinks && item.userLinks.length > 0) {
          const userLink = item.userLinks.find(
            ({ userID }) => userID === user.userID
          );

          if (userLink) {
            userLinkID = userLink.userLinkID;
            selected = true;
            linkName = userLink.linkName;
          }
        }
       
        if (item.email != user.email && !user.admin) {
          items.push({
            userLinkID: userLinkID,
            parentID: item.userID,
            userID: user.userID,
            email: user.email,
            linkName: linkName,
            selected: selected,
          });
        }
      });

      items.sort(function (a, b) {
        if (!a.email || !b.email) {
          return 0;
        }
        
        var x = a.email.toLowerCase();
        var y = b.email.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      this.parentID = item.userID;
      this.dataTableItems = items;
    
      this.copyDataTableItems(this.dataTableItems);
      this.$store.dispatch("reloadUserLinksComponent");
    },
    copyDataTableItems(items) {
      this.originalDataTableItems = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const original = JSON.parse(JSON.stringify(item));
        this.originalDataTableItems.push(original);
      }
    },
    userLinksUpdated(payload) {
      const parentID = payload.parentID;
      const items = payload.list;

      const list = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        let original = this.originalDataTableItems[i];

        if (item.linkName != original.linkName) {
          list.push(item);
        }
      }

      this.userLinksDialog = false;
      this.updateUserLinks(parentID, list);
    },

    updateUserLinks(id, list) {
      this.loading = true;

      this.$store
        .dispatch("updateUserLinks", { parentID: id, list: list })
        .then(
          (response) => {
            this.loading = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.loading = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (api.authenticationError(error.code)) {
              setTimeout(function () {
                router.push({ name: "Home" }).catch(() => {});
              }, api.authErrorRedirectTimeout);
            }
          }
        );
    },
    closeUserLinksDialog() {
      this.userLinksDialog = false;
    },
    close() {
      this.updateUserDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-action {
  width: 80px;
}
</style>
