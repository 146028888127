<template>
  <v-container fluid>
    <v-row id="content">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h2>Update image on Home page</h2>
          </v-col>
          <v-col cols="12" sm="4" class="padding-input" style="height: 175px;">
            <img :src="editedItem.url" class="rounded-card" height="150" />
          </v-col>
          <v-col cols="12" sm="4">
            <p>
              Supported image formats: .jpg, .png. Recommended file size 200 KB
              max.
            </p>
          </v-col>
          <v-col cols="12" sm="4">
            <input
              type="file"
              style="display: none;"
              ref="fileInput"
              accept="image/png, image/jpeg"
              @change="fileSelected"
            />
            <v-btn
              depressed
              tile
              color="primary"
              :disabled="homeCoverImageLoading"
              @click="selectFile()"
              >Select New Image</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomePageImage",

  computed: {
    ...mapGetters(["images", "homeCoverImageLoading", "axiosSource"]),
    getURL() {
      return this.images.home.cover.data;
    },
  },

  data() {
    return {
      dialog: true,
      editedItem: {
        fileName: "",
        url: "",
        image: null,
      },
    };
  },

  methods: {
    selectFile() {
      this.$refs.fileInput.click();
    },
    clearFileInput() {
      this.editedItem.fileName = "";
      this.editedItem.url = "";
      this.editedItem.image = null;
    },
    fileSelected(event) {
      const file = event.target.files[0];

      const maxSize = this.images.home.cover.maxSize;

      if (file.size > maxSize * 1024) {
        const notification = {
          show: true,
          result: false,
          message:
            this.$t("admin.machines.maxImageSize") +
            ` ${this.machineImageMaxSize} KB. ` +
            this.$t("admin.machines.cannotBeUploaded"),
        };

        this.$store.dispatch("showSnackbar", notification);
        return;
      }

      this.editedItem.image = file;

      this.$refs.fileInput.value = null;

      this.$store.dispatch("getImageUrl", this.editedItem.image).then(
        (response) => {
          this.editedItem.url = response.url;
          this.updateImage();
        },
        (error) => {
          this.clearFileInput();

          // eslint-disable-next-line
          console.error("error", error);
        }
      );
    },
    updateImage() {
      const image = {
        page: "Home",
        name: "Cover",
        file: this.editedItem.image,
        loading: true,
      };

      this.$store.dispatch("imageLoading", image);

      image.loading = false;

      this.$store.dispatch("updateImage", image).then(
        (response) => {
          this.$store.dispatch("imageLoading", image);

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.$store.dispatch("imageLoading", image);

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          // eslint-disable-next-line
          console.error("error", error);
        }
      );
    },
    setImage() {
      if (this.getURL) {
        this.editedItem.url = this.getURL;
      } else {
        this.editedItem.url = this.images.home.cover.loadingURL;
        setTimeout(() => this.setImage(), 1000);
      }
    },
  },

  mounted() {
    this.setImage();
  },
};
</script>
